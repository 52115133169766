<script>
  import { BASE_URL } from "../BASE_URL";

  import { isTyping, opened } from "./../store";
  function playSound() {
    player.currentTime = 0;
    player.play();
  }

  let player;
</script>

<audio bind:this={player} src={`${BASE_URL}/449305__dwoboyle__tools-air-pump-03.wav`} />
<nav>
  <div class="tt-wrap">
    <div class="inside">
      <button class="button hollow" on:click={() => opened.update((o) => !o)} on:click={playSound}>
        <svg
          id="Isolation_Mode"
          data-name="Isolation Mode"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64.64 64.64"
          ><defs
            ><style>
              .cls-1 {
                fill: none;
                stroke: #f2d78f;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 10.43px;
              }
            </style></defs
          ><line class="cls-1" x1="59.43" y1="5.21" x2="5.21" y2="59.43" /><line
            class="cls-1"
            x1="59.43"
            y1="59.43"
            x2="5.21"
            y2="5.21"
          /></svg
        >
        <span>סגור חלון</span></button
      >
      <button
        class="button"
        on:click={() => {
          const purchaseBtn = document
            .getElementById("comp-kz8cuu89")
            ?.getElementsByTagName("a")?.[0];
          if (purchaseBtn) purchaseBtn.click();
          playSound();
        }}>לרכישה</button
      >
      {#if $isTyping}
        <div class="is-typing-wrap">
          <button class="button is-typing-button" on:click={playSound}>סיימתי להקליד</button>
        </div>
      {/if}
    </div>
  </div>
</nav>

<style lang="scss">
  nav {
    padding-top: 1em;
    padding-bottom: 1em;
    margin: 0;
    border-bottom: 1px solid var(--main);
    @media (max-width: 767px) {
      border-bottom: 0;
      position: fixed;
      bottom: 0;
      width: 100%;
      right: 0;
      left: 0;
      background-color: var(--sub);
      border-top: 1px solid var(--main);
      opacity: 0.97;
    }
    .inside {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      .is-typing-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: var(--sub);
        @media (min-width: 768px) {
          display: none;
        }
        .is-typing-button {
          width: 100%;
        }
      }
    }
  }
  .button {
    font-family: var(--text-font);
    border-radius: 0.3em;
    background: var(--main);
    color: var(--sub);
    padding: 0.6em 1.1em;
    font-weight: 700;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
    border: none;
    font-size: 1.1em;
    @media (min-width: 400px) {
      font-size: 1.3em;
    }
    svg {
      width: 0.9em;
      height: auto;
      margin-bottom: -0.15em;
      margin-inline-end: 0.2em;
    }
    &.hollow {
      color: var(--main);
      background: var(--sub);
      box-shadow: 0 0 0 0.1em inset var(--main);
    }
  }
</style>
