<script>
  import { BASE_URL } from "../BASE_URL";

  export let bg;
  export let fg;

  import { colors } from "../optionsStore";
  $: flipped = $colors.bg === fg && $colors.fg === bg;

  function playSound() {
    player.currentTime = 0;
    player.play();
  }

  let player;
</script>

<audio
  bind:this={player}
  src={`${BASE_URL}/506052__mellau__button-click-3.wav`}
/>

<div
  class="container"
  on:click={() => {
    if (flipped) {
      $colors.bg = bg;
      $colors.fg = fg;
    } else {
      $colors.bg = fg;
      $colors.fg = bg;
    }
    playSound();
  }}
>
  <div
    class="bg"
    style={`background-color: ${flipped ? fg : bg}`}
    class:selected={(bg === $colors.bg && fg === $colors.fg) || flipped}
  >
    <span class="fg" style={`background-color: ${flipped ? bg : fg}`} />
  </div>
</div>

<style lang="scss">
  .container {
    display: inline-block;
    padding: 0.2em;
    cursor: pointer;
    &:hover {
      .bg {
        .fg {
          transform: scale(1.15);
        }
      }
    }
    .bg {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 10em;
      width: 1em;
      height: 1em;
      transition: all 90ms ease-out;
      box-shadow: 0 0 0 0em var(--sub);
      .fg {
        width: 50%;
        height: 50%;
        border-radius: 10em;
        transition: all 90ms ease-out;
      }
      &.selected {
        box-shadow: 0 0 0 0.1em var(--sub);
      }
    }
  }
</style>
