<script>
  import { BASE_URL } from "../BASE_URL";

  import { liga, salt, colors } from "../optionsStore";
  import Checkbox from "./Checkbox.svelte";
  import Color from "./Color.svelte";
  
  function playSound() {
    player.currentTime = 0;
    player.play();
  }

  let player;
</script>

<audio bind:this={player} src={`${BASE_URL}/268822__kwahmah-02__woodblock.wav`} />

<div class="options">
  <div class="option-col right">
    <div class="checkbox-wrap">
      <input type="checkbox" id="liga" bind:checked={$liga} />
      <label for="liga" on:click={playSound}>
        <Checkbox visible={$liga} />
        <span class="label-text">ליגטורות נסתרות</span>
      </label>
    </div>

    <div class="checkbox-wrap">
      <input type="checkbox" id="salt" bind:checked={$salt} />
      <label for="salt" on:click={playSound}>
        <Checkbox visible={$salt} />
        <span class="label-text">אותיות מיוחדות</span>
      </label>
    </div>
  </div>
  <div class="option-col left">
    <div class="heading">צבעים לכיף שלכם:</div>
    <div class="colors">
      <Color bg="white" fg="black" />
      <!-- <Color bg="black" fg="white" /> -->
      <Color bg="#9e005d" fg="#85d1b1" />
      <Color bg="#ffc600" fg="#c1272d" />
      <Color bg="#ff926f" fg="#00636f" />
      <Color bg="#22b573" fg="#fdf4da" />
      <Color bg="#FFFF82" fg="#5386E4" />
    </div>
  </div>
</div>

<style lang="scss">
  .options {
    background: var(--main);
    color: var(--sub);
    display: flex;
    font-weight: 700;
    border-radius: 0.2em;
    margin: 1em 0;
    position: sticky;
    top:1em;
    box-shadow: 0 0 0.9em 0em var(--sub), 0 0 0.2em 0em var(--sub),  0 -1em 0em 0.3em var(--sub);
    @media (min-width: 44em) {
      font-size: 1.2em;
    }

    .option-col {
      flex-grow: 1;
      padding: 0.7em 0.8em;
      font-size: 0.9em;
      @media (min-width: 44em) {
        padding: 1em 1.2em;
        font-size: 1em;
      }
      &:not(:first-child) {
        border-right: 1px solid;
      }

      &.right {
        padding-top: 0.6em;
      }
      &.left {
        @media (max-width: 420px) {
          display: flex;
          .heading {
            margin-left: 0.7em;
            width: 4em;
            margin-top: 0.3em;
            line-height: 1.1;
          }
        }
        .heading {
        }
        .colors {
          display: flex;
          flex-flow: wrap;
          font-size: 1.3em;
          margin: 0 -0.15em;
          /* gap:0.05em; */
        }
      }
    }
  }
  .checkbox-wrap {
    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
    label {
      white-space: nowrap;
      cursor: pointer;
      padding: 0.2em 0;
      display: block;
      user-select: none;
      .label-text {
        white-space: nowrap;
        margin-right: 0.2em;
      }
    }
  }
</style>
