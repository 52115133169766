<script>
  import { onMount } from "svelte";
  import { BASE_URL } from "./BASE_URL";
  import Nav from "./lib/Nav.svelte";
  import Options from "./lib/Options.svelte";
  import TypeBox from "./lib/TypeBox.svelte";
  import WixFix from "./lib/WixFix.svelte";

  import { opened } from "./store";

  let player;
  function playSound() {
    player.currentTime = 0;
    player.play();
  }

  onMount(() => {
    const handleOpen = (e) => {
      $opened = true;
      playSound();
    };

    const element = document.querySelector("#comp-kdfwglhg2");
    if (element) {
      // console.log("found element:", element);
      element.addEventListener("click", handleOpen);
    }

    const moreElements = [...document.querySelectorAll("a")].filter((a) =>
      a.textContent.includes("להתנסות")
    );

    if (moreElements) {
      moreElements.forEach((element) =>
        element.addEventListener("click", handleOpen)
      );
    }
  });
</script>

<WixFix />
<audio
  bind:this={player}
  src={`${BASE_URL}/449305__dwoboyle__tools-air-pump-03.wav`}
/>

<div class="typetester" class:opened={$opened}>
  <div class="inside">
    <Nav />
    <div class="tt-wrap">
      <h1>נסו את פונט תבלינים</h1>
      <Options />
      <div class="type-boxes">
        <TypeBox size="4em">הקליקו כאן וכתבו...</TypeBox>
        <TypeBox size="2em"
          >פונט תבלינים נוצר בהשראת חנות התבלינים המשפחתית.</TypeBox
        >
        <TypeBox size="1.2em"
          >פונט תבלינים נוצר בהשראת חנות התבלינים המשפחתית. העסק הוקם בשנת 1975
          על ידי סבי אשר עלה מטריפולי וזאת לאחר שאביו היה סוחר תבלינים בלוב.
          שורשים מזרחיים אלו באים לידי ביטוי באופן קליגרפי, עם נגיעה אישית.</TypeBox
        >
        <TypeBox size="7em">אהבה</TypeBox>
        <TypeBox size="4.5em">0123456789</TypeBox>
        <TypeBox size="19em">ש</TypeBox>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  :root {
    --white: #fff;
    --blue: #1b1464;
    --gold: #f2d78f;
    --main: var(--gold);
    --sub: var(--blue);
    --font: "OyTavlinim";
    --text-font: almoni-dl-aaa-400, "FtAcapella", sans-serif;
  }
  @font-face {
    font-family: "OyTavlinim";
    src: url("OyTavlinim-Regular.woff2") format("woff2");
    font-display: block;
  }
  .typetester {
    direction: rtl;
    background: var(--blue);
    color: var(--gold);
    font-family: var(--text-font);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    opacity: 1;
    transition: opacity 120ms ease;
    z-index: 9999999;
    font-size: 16px;
    .inside {
      @media (min-width: 700px) {
        font-size: 1.2em;
      }
    }
  }
  h1 {
    position: relative;
    z-index: 1;
    font-family: "OyTavlinim";
    font-weight: 400;
    /* letter-spacing: 0.02em;
    font-size: 5.63em; */
    text-align: center;
    line-height: 1;
    margin: 0.4em 0 0;
    font-feature-settings: "liga" 01, "calt" 01, "titl" 01;
    font-size: 2.56em;
    white-space: nowrap;
    text-indent: -0.02em;
  }
  @media (min-width: 345px) {
    h1 {
      font-size: 2.6em;
    }
  }
  @media (min-width: 360px) {
    h1 {
      font-size: 2.97em;
    }
  }
  @media (min-width: 400px) {
    h1 {
      font-size: 13vw;
    }
  }
  @media (min-width: 820px) {
    h1 {
      font-size: 5.86em;
    }
  }

  .typetester:not(.opened) {
    opacity: 0;
    pointer-events: none;
  }
  :global(.tt-wrap) {
    max-width: 40em;
    padding: 0 1.2em;
    margin: 0 auto;
  }

  .type-boxes {
    padding-bottom: 5em;
  }
</style>
