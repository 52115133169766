import { writable } from "svelte/store";

const isProduction = import.meta.env.MODE === "production";

if (!isProduction) {
  console.log("Developement Mode");
} else {
  console.log("Production Mode");
}

export const opened = writable(isProduction ? false : true);
export const isTyping = writable(false);


