<script>
  import { isTyping } from "../store";
  import { liga, salt, colors } from "../optionsStore";

  export let size;

  const saltFeatures = ["ss01", "ss02", "ss03", "ss04"];
  const ligaFeatures = ["dlig", "liga", "calt", "titl"];

  function createFeatureStyle(_features, trigger) {
    return _features.map((feature) => `'${feature}' ${trigger ? "01" : "00"}`);
  }

  $: features = [
    ...createFeatureStyle(saltFeatures, $salt),
    ...createFeatureStyle(ligaFeatures, $liga),
  ].join(", ");
</script>

<!-- {$isTyping ? ' $isTyping' : 'NOT'} -->

<div
  on:focus={() => ($isTyping = true)}
  on:blur={() => ($isTyping = false)}
  class="type-box"
  contenteditable="true"
  style={`
  color: ${$colors.fg};
  background: ${$colors.bg};
  outline-color: ${$colors.fg};
  font-feature-settings: ${features};
  font-size:${size};
  `}
>
  <slot />
</div>

<style lang="scss">
  .type-box {
    padding: 1rem;
    background-color: white;
    color: black;
    padding-top: 0.6rem;
    margin-bottom: 0.5rem;
    font-family: var(--font);
    &:focus-visible {
      /* -webkit-focus-ring-color auto 4px; */
      outline: 3px solid;
      outline-style: double;
      border-radius: 0.15rem;
    }
  }
</style>
