<script>
  import { draw } from "svelte/transition";

  export let visible;
</script>

<span>
  {#if visible}
    <svg
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 107.42 107.5"
      ><path
        in:draw={{ duration: 340 }}
        class="cls-1"
        d="M3136,1236.17a162.06,162.06,0,0,1,18.19,27,612.45,612.45,0,0,1,73.19-91.46"
        transform="translate(-3127.98 -1163.74)"
      />
    </svg>
  {/if}
</span>

<style lang="scss">
  span {
    display: inline-block;
    position: relative;
    border-radius: 0.2em;
    border: 2px solid;
    width: 1em;
    height: 1em;
    font-size: 0.9em;
    top: 0.3em;
    svg {
      width: 1.4em;
      height: 1.4em;
      bottom: 0;
      margin: 0.1em;
      left: 0;
      position: absolute;
      .cls-1 {
        fill: none;
        stroke: #1b1464;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 14px;
      }
    }
  }
</style>
